import $ from "jquery";

$(window).on("scroll", function () {
  if ($(this).scrollTop() > 1) {
    $("#pageHeader").addClass("positionSticky");
  } else {
    $("#pageHeader").removeClass("positionSticky");
  }
});

// marquee
if (!customElements.get('marquee-component')) {
  customElements.define('marquee-component', class MarqueeComponent extends HTMLElement {
    constructor() {
      super();

      if(this.dataset.pauseOnHover === 'true'){
        this.addEventListener('mouseenter', this.setOnPause.bind(this));
        this.addEventListener('mouseleave', this.setOnResume.bind(this));
      }
      
      this.onLoad = setTimeout(this.initialize.bind(this), 2000);
    }

    connectedCallback(){
      this.columnsCount = this.querySelectorAll('.marquee-component_col:not([class*="cloned"])').length;
      // document.addEventListener('DOMContentLoaded', function(){this.onload;});
    }

    initialize(){
      this.init = null;
      this.initBounds = this.getColumnsSize() * 2;
      if(this.initBounds === 0) return;
      this.mainBounds = this.dataset.rotation ? this.getBoundingClientRect().height : this.getBoundingClientRect().width;
      this.createClones();
      this.dataset.direction === 'forward' ? this.setOnMarquee(this.initBounds * -1, this.initBounds) : this.setOnMarquee(0, this.initBounds);
    }

    setOnMarquee(pos, cycle){
      const speed = parseFloat(this.dataset.speed);
      let cycleCount = cycle;
      let container = this.querySelector('.marquee-component');
      clearInterval(this.init);
      this.init = setInterval(frame.bind(this), speed);

      function frame(){
        if(cycleCount <= 1){
          clearInterval(this.init);
          if(this.dataset.rotation){
            container.style.top = 0 + 'px';
          } else{
            container.style.left = 0 + 'px';
          }
          this.dataset.direction === 'forward' ? this.setOnMarquee(this.initBounds * -1, this.initBounds) : this.setOnMarquee(0, this.initBounds);
          this.pos = pos;
        
        } else{
          this.dataset.direction === 'forward' ? pos++ : pos--;
          cycleCount--;
          this.pos = pos;
          this.cycleCount = cycleCount;
          
          if(this.dataset.rotation){
            container.style.top = pos + 'px';
          } else{
            container.style.left = pos + 'px';
          }
        }
      }
    }

    setOnPause(){
      clearInterval(this.init);
    }

    setOnResume(){
      this.setOnMarquee(this.pos, this.cycleCount);
    }

    setContainer(size, container){
      if(this.dataset.rotation){
        container.style.height = size + 'px';
        container.style.top = (this.initBounds * -1) + 'px';
      } else{
        container.style.width = size + 'px';
        container.style.left = (this.initBounds * -1) + 'px';
      }
    }

    setTabIndexing(){
      const focusables = this.querySelectorAll('[class*="cloned"] a', '[class*="cloned"] button');
      focusables.forEach(element => {
        element.tabIndex = -1;
        element.setAttribute('aria-hidden', 'true');
      });
    }

    createClones(){
      const wrapper = this.querySelector('.marquee-component');
      this.sizes = this.getColumnsSize();
      if(this.sizes === 0) return;
      this.clone(wrapper);

      while(this.sizes < this.mainBounds){
        this.clone(wrapper);
        this.createClones();
      }
      this.setContainer(this.sizes, wrapper);
      this.setTabIndexing();
    }

    clone(wrapper){
      const columns = this.querySelectorAll('.marquee-component_col:not([class*="cloned"])');
      for(const column of columns){
        const clonedColumn = column.cloneNode(true);
        clonedColumn.classList.add('clonedBefore');
        wrapper.insertBefore(clonedColumn, columns[0]);
      }
      for(const column of columns){
        const clonedColumn = column.cloneNode(true);
        clonedColumn.classList.add('clonedAfter');
        wrapper.appendChild(clonedColumn);
      }
    }

    getColumnsSize(){
      let widths = 0;
      const columns = this.querySelectorAll('.marquee-component_col');
      columns.forEach(column => {
        const bounds = column.getBoundingClientRect();
        widths += this.dataset.rotation ? bounds.height : bounds.width;
      });

      return widths;
    }
  });
}