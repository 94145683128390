import React, { useState, useEffect, useRef } from 'react';
import Slider from "react-slick";
import ScheduleCall from "../../components/scheduleCall";
import ContactForm from "../../components/contactForm";
import Img01 from '../../assets/images/img01.jpg';
import Img02 from '../../assets/images/img02.jpg';
import Img03 from '../../assets/images/img18.jpg';
import Img04 from '../../assets/images/img19.jpg';
import Img05 from '../../assets/images/img20.jpg';
import Img06 from '../../assets/images/img21.jpg';
import PortfolioBg from '../../assets/images/img22.png';
import ServicesBg from '../../assets/images/img23.png';
import Icn01 from '../../assets/images/ico01.svg';
import Icn02 from '../../assets/images/ico02.svg';
import Icn03 from '../../assets/images/ico03.svg';
import Logo from '../../assets/images/logo.svg';
import ImgWaves from '../../assets/images/image-waves.svg';
import IcnCursor from '../../assets/images/logo-cursor.svg';
import membersyImg from '../../assets/images/membersyImg.png';
import cuesImg from '../../assets/images/cuesImg.png';
import staffedUpImg from "../../assets/images/staffedUpImg.png";
import connexTechImg from "../../assets/images/connexTechImg.png";
import boyUImg from "../../assets/images/boyUImg.png";
import shopMeatBoxImg from "../../assets/images/shopMeatBoxImg.png";
import kidduThemeImg from "../../assets/images/kidduThemeImg.png";
import urbanThemeImg from "../../assets/images/urbanThemeImg.png";
import womenWellnessImg from "../../assets/images/womenWellnessImg.png";
import muslimDelightsImg from "../../assets/images/muslimDeligtsImg.png";
import colourTrendImg from "../../assets/images/colourTrendImg.png";
import curatorImg from "../../assets/images/curatorImg.png";
import leamonImg from "../../assets/images/leamonImg.png";
import sparkNewsletterImg from "../../assets/images/sparkNewsletterImg.png";
import addToCartImg from "../../assets/images/addToCartImg.png";
import orderOnWhatsappImg from "../../assets/images/orderOnWhatsappImg.png";
import amDripImg from "../../assets/images/amDripImg.png";
import croInsertedCodeImg from "../../assets/images/croInsertedCodeImg.png";
import testimonialFooterImg from "../../assets/images/testimonial-footer-img.png";
import clientLogo1 from "../../assets/images/curator.png";
import clientLogo2 from "../../assets/images/companylogo2.png";
import clientLogo3 from "../../assets/images/colour.png";
import clientLogo4 from "../../assets/images/cropped-cropped-connexis_logo-final-1.webp";
import flagImage2 from "../../assets/images/flag2.png";
import flagImage3 from "../../assets/images/usflag.png";
import flagImage4 from "../../assets/images/uae.png";
import flagImage5 from "../../assets/images/canada.png";
import flagImage6 from "../../assets/images/singapore.png";
import clientLogo5 from "../../assets/images/companylogo1.webp";
import clientLogo6 from "../../assets/images/companylogo3.png";
import clientLogo7 from "../../assets/images/new-logo.webp";
import clientLogo8 from "../../assets/images/accurek-1.png";
import clientLogo9 from "../../assets/images/membersy.svg";
import clientLogo10 from "../../assets/images/Group-14.svg";
import clientLogo11 from "../../assets/images/Group-108@2x.jpg";
import clientLogo12 from "../../assets/images/logo22.svg";

const Home = () => {

  // Set the page Title for SEO purpose
  useEffect(() => {
    document.title = "Home | Omelatte";
  }, []);

  const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        style={{
          position: "absolute",
          top: "38%",
          right: "1px",
          transform: "translateY(-50%)",
          zIndex: 2,
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <div class="arrow forward"></div>
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        style={{
          position: "absolute",
          top: "38%",
          left: "1px",
          transform: "translateY(-50%)",
          zIndex: 2,
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <div class="arrow backward"></div>
      </div>
    );
  };

  var settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    speed: 1500,
    autoplaySpeed: 20000000,
    autoplay: true,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  var settingsTwo = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    speed: 500,
    fade: true,
    autoplay: true,
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const sectionRef = useRef(null);

  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf('#') + 1
    );
    const element = document.getElementById(href);
    const section = sectionRef.current;

    if (element && section) {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5, // Adjust this threshold as needed
      };

      const callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log('The section is in the viewport')
            // The section is in the viewport
            // You can activate the link or perform other actions here
          } else {
            console.log('The section is out the viewport')
            // The section is out of the viewport
          }
        });
      };

      const observer = new IntersectionObserver(callback, options);
      observer.observe(section);

      // Clean up the observer when the component unmounts
      return () => {
        observer.unobserve(section);
      };
    }
  }, []);

  return (
    <>
      <section className="bannerSection">
        <div className='container'>
          <div className='omlRow'>
            <div className='omlCol bnrCol70'>
              <header className='sectionHeader'>
                <h1 className='h1 txtUppercase'>
                  TURNING STARTUP
                  <span className='txtOutline'> DREAMS <span className='icn icn-arrow-up-right'></span></span>
                  INTO CODE
                </h1>
                <strong className='txtTag txtUppercase'>YOUR STARTUP, OUR PASSION</strong>
              </header>
            </div>
            <div className='omlCol bnrCol30'>
              <div className='bnrWidget'>
                <div className='wdgHolder'>
                  <span className='gradLne'></span>
                  <span className='wdgTag bgYellow'>SaaS</span>
                  <span className='gradLne'></span>
                  <span className='gradLne'></span>
                  <span className='wdgTag bgGreen'>
                    <img src={IcnCursor} className='imgFluid flotingIcn' alt="image description" />
                    Development
                  </span>
                  <span className='gradLne'></span>
                  <span className='gradLne'></span>
                  <span className='wdgTag bgOrange'>Creative</span>
                </div>
                <img src={ImgWaves} className='imgFluid flotingObject' alt="image description" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <aside className='callBookAside fontPoppins'>
        <div className='container'>
          <a href="/pricing" className='estCstBtn'>
            <span className='lgoWrap'>
              <img src={Logo} className='imgFluid' alt="Omelatte" />
            </span>
          </a>
        </div>
      </aside>
      <section className='secvicesSection'>
        <div className='container'>
          <div className='imgWrap txtCenter'>
            <img src={Img01} className='imgFluid' alt="Description" />
          </div>
          <div ref={sectionRef}>
            <header className='sectionHeader txtCenter'>
              <span id="services" className='scrollRef'></span>
              <h1 className='h1 txtUppercase'>
                <span className='txtOutline'>Our Comprehensive </span>
                Service Offerings
              </h1>
            </header>
            <ul className='servcList'>
              <li>
                <article className='srvcWidget'>
                  <strong className='srvTitle'>Software Development</strong>
                  <p>
                    Omelatte specializes in cutting-edge software product development services designed
                    to enhance businesses in the digital realm. Our approach is tailored to create efficient
                    software, optimize performance, ensure robust security, and deliver seamless user experiences,
                    positioning us as a premier software development services provider.
                  </p>
                  <a href="/services/software-development" className='readMoreLnk'>Read More <span className='icn icn-arrow-up-right'></span></a>
                </article>
              </li>
              <li>
                <article className='srvcWidget'>
                  <strong className='srvTitle'>MVP Development</strong>
                  <p>
                    Ensure the prosperity of your product concept isn't left to fate. Prior to diving in completely,
                    adopt an MVP software development strategy to authenticate your concept, recognize customer
                    requirements,and collect crucial user feedback. This process unveils valuable insights, aiding in
                    the creation of an impressive product that not only satisfies customers but also positions you for
                    sustained success.
                  </p>
                  <a href="/services/mvp-development" className='readMoreLnk'>Read More <span className='icn icn-arrow-up-right'></span></a>
                </article>
              </li>
              <li>
                <article className='srvcWidget'>
                  <strong className='srvTitle'>UX/UI Design</strong>
                  <p>Omelatte provides forward-thinking UI & UX design solutions, ensuring timely delivery of outstanding final products. Our internal creative team operates as a specialized design studio within our full-service software firm, committed to producing deeply immersive mobile and web apps, covering prototype creation, testing, and user experience analysis.</p>
                  <a href="/services/ux-ui-design" className='readMoreLnk'>Read More <span className='icn icn-arrow-up-right'></span></a>
                </article>
              </li>
              <li>
                <article className='srvcWidget'>
                  <strong className='srvTitle'>Cloud Hosting</strong>
                  <p>Choosing software architecture is one of the earliest and most critical decisions for your software. But, how do you decide between cloud-based and on-premise servers? This is where you need trusted developers with experience in both cloud and on-prem deployment by your side.</p>
                  <a href="/services/cloud-hosting" className='readMoreLnk'>Read More <span className='icn icn-arrow-up-right'></span></a>
                </article>
              </li>
              <li>
                <article className='srvcWidget'>
                  <strong className='srvTitle'>Mobile Apps</strong>
                  <p>
                    Omellate provides comprehensive application design, integration,
                    and management solutions. Whether it's a consumer-focused application
                    or a transformative enterprise-level solution, we oversee the entire
                    process from conception to delivery and ongoing maintenance.
                  </p>
                  <a href="/services/mobile-apps" className='readMoreLnk'>Read More <span className='icn icn-arrow-up-right'></span></a>
                </article>
              </li>
              <li>
                <article className='srvcWidget'>
                  <strong className='srvTitle'>Support & Maintenance</strong>
                  <p>Should you seek to expand your project, integrate fresh functionalities, or modernize outdated code, our Customer Support team stands prepared. Our services for maintaining and supporting applications are tailored to enhance and prolong the lifespan of your solution..</p>
                  <a href="/services/support-and-maintenance" className='readMoreLnk'>Read More <span className='icn icn-arrow-up-right'></span></a>
                </article>
              </li>
            </ul>
          </div>
        </div>
        <div className='bgImg' style={{ backgroundImage: `url(${ServicesBg})` }}></div>
      </section>
      <section className='gtYrAppSection'>
        <div className='container'>
          <div className='sctnTagElem'>
            <span>Not sure what you need? GET A FREE</span>
            <strong onClick={openModal} className='txtTag txtUppercase'>Consultation</strong>
          </div>
          <div className='omlRow'>
            <div className='omlCol gyaCol40'>
              <header className='sectionHeader'>
                <h1 className='h1 txtUppercase'>
                  <span className='txtOutline'>GET YOUR MVP </span>
                  DEVELOPED IN NO TIME
                </h1>
              </header>
            </div>
            <div className='omlCol gyaCol60'>
              <ul className='appFtrList'>
                <li>
                  <article className='appFtrWdgt'>
                    <div className='imgWrap'>
                      <img src={Icn01} className='imgFluid' alt="image description" />
                    </div>
                    <div className='descWrap'>
                      <strong className='titleTxt'>On-Time, Every Time</strong>
                      <p>Experience lightning-fast MVP delivery with us – your MVP will be ready in no time.</p>
                    </div>
                  </article>
                </li>
                <li>
                  <article className='appFtrWdgt'>
                    <div className='imgWrap'>
                      <img src={Icn02} className='imgFluid' alt="image description" />
                    </div>
                    <div className='descWrap'>
                      <strong className='titleTxt'>Uncompromising Quality</strong>
                      <p>We prioritize top-notch quality, adhering to the highest standards. Committed to delivering excellence.</p>
                    </div>
                  </article>
                </li>
                <li>
                  <article className='appFtrWdgt'>
                    <div className='imgWrap'>
                      <img src={Icn03} className='imgFluid' alt="image description" />
                    </div>
                    <div className='descWrap'>
                      <strong className='titleTxt'>Transparent & Fair Pricing</strong>
                      <p>Subscribe to our fixed-price model. Simply add your tasks, and we'll handle them one by one, ensuring transparent and fair pricing.</p>
                    </div>
                  </article>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className='blogSection'>
        <span className='flotScrlRef' id="our-work"></span>
        <div className='container'>
          <header className='sectionHeader'>
            <strong className='txtTag txtUppercase'>Made by Omelatte</strong>
            <h1 className='h1 txtUppercase'>
              <span className='txtOutline'>Results </span>
              Delivered
            </h1>
          </header>
          <div className='omlRow wthOtSlider'>
            <div className='omlCol bgsCol45'>
              <article className='blogPost' data-feature="Latest">
                <div className='imgWrap'>
                  <a href='/portfolio/membersy'><img src={membersyImg} className='imgFluid' alt=" description" /></a>
                </div>
                <div className='descpWrap'>
                  <div className='infoWrap'>
                    <span className='blgCat'></span>
                    <ul className='tagList'>
                      <li className='active'><a href="/portfolio/membersy">Product</a></li>
                      <li className='active'><a href="/portfolio/membersy">Healthtech</a></li>
                      <li className='active'><a href="/portfolio/membersy">Web App</a></li>
                      <li className='active'><a href="/portfolio/membersy">Web Design</a></li>
                    </ul>
                  </div>
                  <h1 className='h1'><a href="/portfolio/membersy">Membersy.com</a></h1>
                  <p>Membersy connects individuals to affordable healthcare and empowers providers with the tools and support they need to build lasting patient relationships...<a href="/portfolio/membersy" className='readmore'>Read more</a></p>
                </div>
              </article>
            </div>
            <div className='omlCol bgsCol55'>
              <article className='blogPost'>
                <div className='imgWrap'>
                  <a href="/portfolio/get-cues"><img src={cuesImg} className='imgFluid' alt=" description" /></a>
                </div>
                <div className='descpWrap'>
                  <div className='infoWrap'>
                    <span className='blgCat'></span>
                    <ul className='tagList'>
                      <li className='active'><a href="/portfolio/get-cues">Mobile App</a></li>
                      <li className='active'><a href="/portfolio/get-cues">Product</a></li>
                      <li className='active'><a href="/portfolio/get-cues">Fintech</a></li>
                      <li className='active'><a href="/portfolio/get-cues">Web App</a></li>
                      <li className='active'><a href="/portfolio/get-cues">Web Design</a></li>
                    </ul>
                  </div>
                  <h1 className='h1'><a href="/portfolio/get-cues">GetCues.com</a></h1>
                  <p>GetCues.com offers employee recognition tools that assist companies in boosting employee engagement and performance. We have built this website with a combination of PHP Laravel and ReactJS, delivering a dynamic front-end and powerful back-end...<a href="/portfolio/get-cues" className='readmore'>Read more</a> </p>
                </div>
              </article>
            </div>
          </div>
          <div className='bgSliderWrap'>
            <Slider {...settings}>
              <div>
                <article className='blogPost'>
                  <div className='imgWrap'>
                    <a href="/portfolio/pet-bond"><img src={Img06} className='imgFluid' alt=" description" /></a>
                  </div>
                  <div className='descpWrap'>
                    <div className='infoWrap'>
                      <span className='blgCat'></span>
                      <ul className='tagList'>
                        <li><a href="/portfolio/pet-bond">Branding</a></li>
                        <li className='active'><a href="/portfolio/pet-bond">Mobile App</a></li>
                        <li className='active'><a href="/portfolio/pet-bond">Product</a></li>
                        <li className='active'><a href="/portfolio/pet-bond">Web App</a></li>
                        <li className='active'><a href="/portfolio/pet-bond">Web Design</a></li>
                      </ul>
                    </div>
                    <h1 className='h1'><a href="/portfolio/pet-bond">PetBond</a></h1>
                    <p>
                      It is a Pet Adoption portal to discover your next pet. Developed with PHP Laravel
                      and ReactJS, Petbond.com integrates AWS for its hosting and cloud infrastructure,
                      including RDS for database solutions and S3 for data storage...<a href="/portfolio/pet-bond" className='readmore'>Read more</a>
                    </p>
                  </div>
                </article>
              </div>

              <div>
                <article className='blogPost'>
                  <div className='imgWrap'>
                    <a href="/portfolio/staffed-up"><img src={staffedUpImg} className='imgFluid' alt=" description" /></a>
                  </div>
                  <div className='descpWrap'>
                    <div className='infoWrap'>
                      <span className='blgCat'></span>
                      <ul className='tagList'>
                        <li className='active'><a href="/portfolio/staffed-up">AI</a></li>
                        <li className='active'><a href="/portfolio/staffed-up">Product</a></li>
                        <li className='active'><a href="/portfolio/staffed-up">Web App</a></li>
                        <li className='active'><a href="/portfolio/staffed-up">Web Design</a></li>
                      </ul>
                    </div>
                    <h1 className='h1'><a href="/portfolio/staffed-up">StaffedUp</a></h1>
                    <p>
                      StaffedUp.com is a hiring software to help business owners to recruit the best
                      employees. The website leverages the power of Python for backend processes, while
                      Angular and ReactJS bring a smooth...<a href="/portfolio/staffed-up" className='readmore'>Read more</a>
                    </p>
                  </div>
                </article>
              </div>

              <div>
                <article className='blogPost' data-feature="Latest Project">
                  <div className='imgWrap'>
                    <a href="/portfolio/connex-technologies"><img src={connexTechImg} className='imgFluid' alt=" description" /></a>
                  </div>
                  <div className='descpWrap'>
                    <div className='infoWrap'>
                      <span className='blgCat'></span>
                      <ul className='tagList'>
                        <li className='active'><a href="/portfolio/connex-technologies">Mobile App</a></li>
                        <li className='active'><a href="/portfolio/connex-technologies">Product</a></li>
                        <li className='active'><a href="/portfolio/connex-technologies">IOT</a></li>
                        <li className='active'><a href="/portfolio/connex-technologies">Big Data</a></li>
                        <li className='active'><a href="/portfolio/connex-technologies">Web App</a></li>
                        <li className='active'><a href="/portfolio/connex-technologies">Web Design</a></li>
                      </ul>
                    </div>
                    <h1 className='h1'><a href="/portfolio/connex-technologies">Connexis Technologies</a></h1>
                    <p>
                      Connexis Technologies is a GPS Satellite Vehicle Tracking System.
                      It is an advanced IoT-driven platform built with PHP Laravel and Angular,
                      supported by AWS services, including RDS and S3 for hosting and data storage...<a href="/portfolio/connex-technologies" className='readmore'>Read more</a>
                    </p>
                  </div>
                </article>
              </div>

              <div>
                <article className='blogPost' data-feature="Latest Project">
                  <div className='imgWrap'>
                    <a href="/portfolio/boyu-online"><img src={boyUImg} className='imgFluid' alt="image description" /></a>
                  </div>
                  <div className='descpWrap'>
                    <div className='infoWrap'>
                      <span className='blgCat'></span>
                      <ul className='tagList'>
                        <li className='active'><a href="/portfolio/boyu-online">E-Commerce</a></li>
                        <li className='active'><a href="/portfolio/boyu-online">Shopify Stores</a></li>
                        <li className='active'><a href="/portfolio/boyu-online">Web Design</a></li>
                      </ul>
                    </div>
                    <h1 className='h1'><a href="/portfolio/boyu-online">BoyU Online</a></h1>
                    <p>
                      This health and wellness store is crafted for the tech-savvy consumer,
                      offering advanced product filters and a wishlist feature to improve user
                      experience. With a minimalistic, modern design and quick-view options, it
                      caters to the growing demand for clean aesthetics in online shopping...<a href="/portfolio/boyu-online" className='readmore'>Read more</a>
                    </p>
                  </div>
                </article>
              </div>

              <div>
                <article className='blogPost' data-feature="Latest Project">
                  <div className='imgWrap'>
                    <a href="/portfolio/shop-meat-box"><img src={shopMeatBoxImg} className='imgFluid' alt="image description" /></a>
                  </div>
                  <div className='descpWrap'>
                    <div className='infoWrap'>
                      <span className='blgCat'></span>
                      <ul className='tagList'>
                        <li className='active'><a href="/portfolio/shop-meat-box">E-Commerce</a></li>
                        <li className='active'><a href="/portfolio/shop-meat-box">Shopify Store</a></li>
                        <li className='active'><a href="/portfolio/shop-meat-box">Web Design</a></li>
                      </ul>
                    </div>
                    <h1 className='h1'><a href="/portfolio/shop-meat-box">ShopMeatBox</a></h1>
                    <p>
                      ShopMeatBox’s Shopify store is optimized for the food industry, featuring
                      custom sections for product freshness and delivery options, as well as a
                      subscription box feature for regular orders...<a href="/portfolio/shop-meat-box" className='readmore'>Read more</a>
                    </p>
                  </div>
                </article>
              </div>

              <div>
                <article className='blogPost' data-feature="Latest Project">
                  <div className='imgWrap'>
                    <a href="/portfolio/kiddu-theme"><img src={kidduThemeImg} className='imgFluid' alt="image description" /></a>
                  </div>
                  <div className='descpWrap'>
                    <div className='infoWrap'>
                      <span className='blgCat'></span>
                      <ul className='tagList'>
                        <li className='active'><a href="/portfolio/kiddu-theme">Shopify</a></li>
                        <li className='active'><a href="/portfolio/kiddu-theme">Themes</a></li>
                        <li className='active'><a href="/portfolio/kiddu-theme">Design</a></li>
                      </ul>
                    </div>
                    <h1 className='h1'><a href="/portfolio/kiddu-theme">Kiddu Theme</a></h1>
                    <p>
                      The Kidu Theme is a playful, vibrant theme designed with children’s clothing and
                      toy stores in mind. Built on Shopify’s Liquid framework with Vanilla JS for smooth
                      interactions and improved load times...<a href="/portfolio/kiddu-theme" className='readmore'>Read more</a>
                    </p>
                  </div>
                </article>
              </div>

              <div>
                <article className='blogPost' data-feature="Latest Project">
                  <div className='imgWrap'>
                    <a href="/portfolio/urban-theme"><img src={urbanThemeImg} className='imgFluid' alt="description" /></a>
                  </div>
                  <div className='descpWrap'>
                    <div className='infoWrap'>
                      <span className='blgCat'></span>
                      <ul className='tagList'>
                        <li className='active'><a href="/portfolio/urban-theme">Shopify</a></li>
                        <li className='active'><a href="/portfolio/urban-theme">Themes</a></li>
                        <li className='active'><a href="/portfolio/urban-theme">Design</a></li>
                      </ul>
                    </div>
                    <h1 className='h1'><a href="/portfolio/urban-theme">Urban Theme</a></h1>
                    <p>
                      Designed for modern, minimalist brands, the Urban Theme brings a sophisticated,
                      streamlined layout ideal for lifestyle and apparel stores targeting the
                      fashion-forward...<a href="/portfolio/urban-theme" className='readmore'>Read more</a>
                    </p>
                  </div>
                </article>
              </div>

              <div>
                <article className='blogPost' data-feature="Latest Project">
                  <div className='imgWrap'>
                    <a href="/portfolio/women-wellness"><img src={womenWellnessImg} className='imgFluid' alt=" description" /></a>
                  </div>
                  <div className='descpWrap'>
                    <div className='infoWrap'>
                      <span className='blgCat'></span>
                      <ul className='tagList'>
                        <li className='active'><a href="/portfolio/women-wellness">Product</a></li>
                        <li className='active'><a href="/portfolio/women-wellness">Web App</a></li>
                        <li className='active'><a href="/portfolio/women-wellness">Web Design</a></li>
                      </ul>
                    </div>
                    <h1 className='h1'><a href="/portfolio/women-wellness">Women Wellness</a></h1>
                    <p>
                      This women's health website was built using PHP Laravel with enhanced security
                      features and scalability via Cloudflare. Hosted on DigitalOcean, the platform
                      uses efficient caching and optimized code to deliver a smooth user experience...<a href="/portfolio/women-wellness" className='readmore'>Read more</a>
                    </p>
                  </div>
                </article>
              </div>

              <div>
                <article className='blogPost' data-feature="Latest Project">
                  <div className='imgWrap'>
                    <a href="/portfolio/muslim-delights"><img src={muslimDelightsImg} className='imgFluid' alt=" description" /></a>
                  </div>
                  <div className='descpWrap'>
                    <div className='infoWrap'>
                      <span className='blgCat'></span>
                      <ul className='tagList'>
                        <li className='active'><a href="/portfolio/muslim-delights">Product</a></li>
                        <li className='active'><a href="/portfolio/muslim-delights">Web App</a></li>
                        <li className='active'><a href="/portfolio/muslim-delights">Web Design</a></li>
                      </ul>
                    </div>
                    <h1 className='h1'><a href="/portfolio/muslim-delights">Muslim Delights</a></h1>
                    <p>
                      This a wedding and catering business in Singapore. Omelatte has developed this
                      site using PHP Laravel and ReactJS, with DigitalOcean handling its hosting
                      requirements...<a href="/portfolio/muslim-delights" className='readmore'>Read more</a>
                    </p>
                  </div>
                </article>
              </div>

              <div>
                <article className='blogPost' data-feature="Latest Project">
                  <div className='imgWrap'>
                    <a href="/portfolio/cro"><img src={croInsertedCodeImg} className='imgFluid' alt=" description" /></a>
                  </div>
                  <div className='descpWrap'>
                    <div className='infoWrap'>
                      <span className='blgCat'></span>
                      <ul className='tagList'>
                        <li className='active'><a href="/portfolio/cro">Shopify</a></li>
                        <li className='active'><a href="/portfolio/cro">Apps</a></li>
                      </ul>
                    </div>
                    <h1 className='h1'><a href="/portfolio/cro">CRO - Insert Code</a></h1>
                    <p>
                      The code insertion app offers an efficient solution for store owners looking to
                      add custom code snippets, such as tracking pixels or custom scripts, without
                      technical assistance...<a href="/portfolio/cro" className='readmore'>Read more</a>
                    </p>
                  </div>
                </article>
              </div>

              <div>
                <article className='blogPost' data-feature="Latest Project">
                  <div className='imgWrap'>
                    <a href="/portfolio/newsletter-email-sync"><img src={sparkNewsletterImg} className='imgFluid' alt=" description" /></a>
                  </div>
                  <div className='descpWrap'>
                    <div className='infoWrap'>
                      <span className='blgCat'></span>
                      <ul className='tagList'>
                        <li className='active'><a href="/portfolio/newsletter-email-sync">Shopify</a></li>
                        <li className='active'><a href="/portfolio/newsletter-email-sync">Apps</a></li>
                      </ul>
                    </div>
                    <h1 className='h1'><a href="/portfolio/newsletter-email-sync">Spark Newsletter Email Sync</a></h1>
                    <p>
                      This Email Sync application is designed for stores focused on effective email
                      marketing and customer engagement. Using the MERN stack, this app allows seamless
                      synchronization ...<a href="/portfolio/newsletter-email-sync" className='readmore'>Read more</a>
                    </p>
                  </div>
                </article>
              </div>

              <div>
                <article className='blogPost' data-feature="Latest Project">
                  <div className='imgWrap'>
                    <a href="/portfolio/add-to-cart-button"><img src={addToCartImg} className='imgFluid' alt=" description" /></a>
                  </div>
                  <div className='descpWrap'>
                    <div className='infoWrap'>
                      <span className='blgCat'></span>
                      <ul className='tagList'>
                        <li className='active'><a href="/portfolio/add-to-cart-button">Shopify</a></li>
                        <li className='active'><a href="/portfolio/add-to-cart-button">Apps</a></li>
                      </ul>
                    </div>
                    <h1 className='h1'><a href="/portfolio/add-to-cart-button">Add to Cart Button</a></h1>
                    <p>
                      This Shopify app enhances the checkout process by introducing a floating, highly
                      customizable add-to-cart button that stays visible as customers browse products.
                      Developed with React and Node.js, this app offers rapid load times and a
                      non-intrusive design that aligns with any theme...<a href="/portfolio/add-to-cart-button" className='readmore'>Read more</a>
                    </p>
                  </div>
                </article>
              </div>

              <div>
                <article className='blogPost' data-feature="Latest Project">
                  <div className='imgWrap'>
                    <a href="/portfolio/ai-products-description"><img src={orderOnWhatsappImg} className='imgFluid' alt=" description" /></a>
                  </div>
                  <div className='descpWrap'>
                    <div className='infoWrap'>
                      <span className='blgCat'></span>
                      <ul className='tagList'>
                        <li className='active'><a href="/portfolio/ai-products-description">Shopify</a></li>
                        <li className='active'><a href="/portfolio/ai-products-description">Apps</a></li>
                      </ul>
                    </div>
                    <h1 className='h1'><a href="/portfolio/ai-products-description">AI Products Description</a></h1>
                    <p>
                      The Spark AI app harnesses the power of AI to generate engaging and SEO-optimized product
                      titles and descriptions, saving store owners countless hours. Using the MERN stack, this
                      app’s intuitive interface allows users to enter minimal information...<a href="/portfolio/ai-products-description" className='readmore'>Read more</a>
                    </p>
                  </div>
                </article>
              </div>

              <div>
                <article className='blogPost' data-feature="Latest Project">
                  <div className='imgWrap'>
                    <a href="/portfolio/leamon"><img src={leamonImg} className='imgFluid' alt=" description" /></a>
                  </div>
                  <div className='descpWrap'>
                    <div className='infoWrap'>
                      <span className='blgCat'></span>
                      <ul className='tagList'>
                        <li className='active'><a href="/portfolio/leamon">Shopify</a></li>
                        <li className='active'><a href="/portfolio/leamon">Apps</a></li>
                      </ul>
                    </div>
                    <h1 className='h1'><a href="/portfolio/leamon">Leamon</a></h1>
                    <p>
                      Leamon is a versatile app developed with the MERN stack that enables store owners
                      to customize their storefront layouts and sections dynamically...<a href="/portfolio/leamon" className='readmore'>Read more</a>
                    </p>
                  </div>
                </article>
              </div>

              {/* <div>
                <article className='blogPost' data-feature="Latest Project">
                  <div className='imgWrap'>
                    <a href="/portfolio/am-drip"><img src={amDripImg} className='imgFluid' alt=" description" /></a>
                  </div>
                  <div className='descpWrap'>
                    <div className='infoWrap'>
                      <span className='blgCat'></span>
                      <ul className='tagList'>
                        <li className='active'><a href="/portfolio/am-drip">E-Commerce</a></li>
                        <li className='active'><a href="/portfolio/am-drip">Shopify Stores</a></li>
                        <li className='active'><a href="/portfolio/am-drip">Web Design</a></li>
                      </ul>
                    </div>
                    <h1 className='h1'><a href="/portfolio/am-drip">AM Drip</a></h1>
                    <p>
                      AM Drip isn’t just a sportswear store—it’s a revolution in period-active apparel.
                      Designed with dynamic filtering and quick view functionality, it streamlines
                      browsing through ...<a href="/portfolio/am-drip" className='readmore'>Read more</a>
                    </p>
                  </div>
                </article>
              </div> */}

              <div>
                <article className='blogPost' data-feature="Latest Project">
                  <div className='imgWrap'>
                    <a href="/portfolio/curator-paints"><img src={curatorImg} className='imgFluid' alt=" description" /></a>
                  </div>
                  <div className='descpWrap'>
                    <div className='infoWrap'>
                      <span className='blgCat'></span>
                      <ul className='tagList'>
                        <li className='active'><a href="/portfolio/curator-paints">E-Commerce</a></li>
                        <li className='active'><a href="/portfolio/curator-paints">Shopify Stores</a></li>
                        <li className='active'><a href="/portfolio/curator-paints">Web Design</a></li>
                      </ul>
                    </div>
                    <h1 className='h1'><a href="/portfolio/curator-paints">Curator Paints</a></h1>
                    <p>
                      Curator Paints’ Shopify store is developed for creativity, featuring an interactive
                      color selection tool that lets customers experiment with hues before purchase.
                      Integrated with advanced dynamic filtering and quick view options...<a href="/portfolio/curator-paints" className='readmore'>Read more</a>
                    </p>
                  </div>
                </article>
              </div>

              <div>
                <article className='blogPost' data-feature="Latest Project">
                  <div className='imgWrap'>
                    <a href="/portfolio/colour-trend-ie"><img src={colourTrendImg} className='imgFluid' alt=" description" /></a>
                  </div>
                  <div className='descpWrap'>
                    <div className='infoWrap'>
                      <span className='blgCat'></span>
                      <ul className='tagList'>
                        <li className='active'><a href="/portfolio/colour-trend-ie">E-Commerce</a></li>
                        <li className='active'><a href="/portfolio/colour-trend-ie">Shopify Stores</a></li>
                        <li className='active'><a href="/portfolio/colour-trend-ie">Web Design</a></li>
                      </ul>
                    </div>
                    <h1 className='h1'><a href="/portfolio/colour-trend-ie">ColourTrend</a></h1>
                    <p>
                      For Colourtrend, Omelatte built a store that emphasizes personalization and
                      accessibility, providing dynamic filtering and easy product comparisons to
                      help users select paints suited to their needs...<a href="/portfolio/colour-trend-ie" className='readmore'>Read more</a>
                    </p>
                  </div>
                </article>
              </div>

            </Slider>
          </div>
        </div>
        <div className='bgImg' style={{ backgroundImage: `url(${PortfolioBg})` }}></div>
      </section>
      <section className="testimonialSection">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className='testimonial-child-1'>
                <Slider {...settingsTwo}>
                  <div>
                    <div className="testi-content-wrap">
                    <h6 className='happy'>HAPPY</h6>
                    <h6 className='customer'>CUSTOMER</h6>
                    <h6 className='stories'>STORIES</h6>
                    <p className='testimonial-description'>
                    We have worked with AlphaRages on multiple projects for our website developments. The company went beyond our expectations. Along with their technical deliveries, their consultancy has also helped us to see through some important aspect on how our website should look like and what should be the tech stack etc. The important things which I love about AlphaRages are their reliability and on-time support to all the issues we faced/were facing. Every guy at AlphaRages we have interacted with is super professional and trustworthy. IMO they are the best in industry with comparatively much less consultancy fee.
                    </p>
                    <div className='testimonial-footer'>
                      <div className='testimonial-client-name'>
                        <p className='testimonial-name'>
                          M
                        </p>
                        <div className="testi-info-wrapper">
                          <p>Mr Naveed</p>
                          <p className='testimonial-ceo'>MD Al-Imran UAE</p>
                        </div>
                      </div>
                      <div>
                        <img src={testimonialFooterImg} alt="Testimonial Footer" className='testimonial-img' />
                      </div>
                    </div>
                    </div>
                  </div>
                  <div>
                    <div className="testi-content-wrap">
                    <h6 className='happy'>HAPPY</h6>
                    <h6 className='customer'>CUSTOMER</h6>
                    <h6 className='stories'>STORIES</h6>
                    <p className='testimonial-description'>
                      Faisal, Adnan and all the team at Alpha Rages have been excellent to work with over the past 7 to 8 years. They are experts in their fields and they have extreme patience when dealing with complex problems. They always find a solution. It has been a pleasure working with Alpha Rages and see them progress and thrive in a difficult industry. We completed some very big projects together and I could recommend them highly enough. I wis Faisal and team continued success, happiness and health in the future.
                    </p>
                    <div className='testimonial-footer'>
                      <div className='testimonial-client-name'>
                        <p className='testimonial-name'>
                          R
                        </p>
                        <div className="testi-info-wrapper">
                          <p>Ray Carolan</p>
                          <p className='testimonial-ceo'>MD Lakeland Kayaks Ltd, SKIPJAK.ie, Digital Eire Ltd & ZenDigital</p>
                        </div>
                      </div>
                      <div>
                        <img src={testimonialFooterImg} alt="Testimonial Footer" className='testimonial-img' />
                      </div>
                    </div>
                    </div>
                  </div>
                  <div>
                    <div className="testi-content-wrap">
                    <h6 className='happy'>HAPPY</h6>
                    <h6 className='customer'>CUSTOMER</h6>
                    <h6 className='stories'>STORIES</h6>
                    <p className='testimonial-description'>
                      I have worked with Adnan and Faisal in AlphaRages on many projects and found the guys to be very reliable with a strong work ethic that gets projects done and within budget.
                    </p>
                    <div className='testimonial-footer'>
                      <div className='testimonial-client-name'>
                        <p className='testimonial-name'>
                          R
                        </p>
                        <div className="testi-info-wrapper">
                          <p>Robert Ferguson</p>
                          <p className='testimonial-ceo'>Web Development Project Manager</p>
                        </div>
                      </div>
                      <div>
                        <img src={testimonialFooterImg} alt="Testimonial Footer" className='testimonial-img' />
                      </div>
                    </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="col">
              <h6 className='happy'>WORKING</h6>
              <div className='with-clients'>
                <h6 className='customer'>WITH</h6>
                <h6 className='stories'>CLIENTS</h6>
              </div>
              <div className='working-with-clients-section'>
                <marquee-component data-rotation="down" data-direction="forward" data-speed="20">
                  <div class="marquee-component">
                    <div className="marquee-component_col mdmws_item clonedBefore">
                      <div className="client-logo-wrapper">
                        <div className='testimonial-logo'>
                          <img src={clientLogo6} alt="staffed up"/>
                        </div>
                        <div className="country-flag-text">
                          <div className="flag-image">
                            <img src={flagImage3}  />
                          </div>
                          <span className="country-name">USA</span>
                        </div>
                      </div>
                    </div>
                    <div className="marquee-component_col mdmws_item clonedBefore">
                      <div className="client-logo-wrapper">
                        <div className='testimonial-logo'>
                          <img src={clientLogo2} alt="pet bond"/>
                        </div>
                        <div className="country-flag-text">
                          <div className="flag-image">
                            <img src={flagImage2}  />
                          </div>
                          <span className="country-name">Ireland</span>
                        </div>
                      </div>
                    </div>
                    <div className="marquee-component_col mdmws_item clonedBefore">
                      <div className="client-logo-wrapper">
                        <div className='testimonial-logo'>
                          <img src={clientLogo4} alt="connexis"/>
                        </div>
                        <div className="country-flag-text">
                          <div className="flag-image">
                            <img src={flagImage4}  />
                          </div>
                          <span className="country-name">UAE</span>
                        </div>
                      </div>
                    </div>
                    <div className="marquee-component_col mdmws_item clonedBefore">
                      <div className="client-logo-wrapper">
                        <div className='testimonial-logo'>
                          <img src={clientLogo3} alt="colour trend"/>
                        </div>
                        <div className="country-flag-text">
                          <div className="flag-image">
                            <img src={flagImage2}  />
                          </div>
                          <span className="country-name">Ireland</span>
                        </div>
                      </div>
                    </div>
                    <div className="marquee-component_col mdmws_item clonedBefore">
                      <div className="client-logo-wrapper">
                        <div className='testimonial-logo'>
                          <img src={clientLogo1} alt="Curator Paints"/>
                        </div>
                        <div className="country-flag-text">
                          <div className="flag-image">
                            <img src={flagImage2}  />
                          </div>
                          <span className="country-name">Ireland</span>
                        </div>
                      </div>
                    </div>
                    <div className="marquee-component_col mdmws_item clonedBefore">
                      <div className="client-logo-wrapper">
                        <div className='testimonial-logo'>
                          <img src={clientLogo9}/>
                        </div>
                        <div className="country-flag-text">
                          <div className="flag-image">
                            <img src={flagImage3}  />
                          </div>
                          <span className="country-name">USA</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </marquee-component>
                <marquee-component data-rotation="up" data-direction="backward" data-speed="20">
                  <div class="marquee-component">
                    <div className="marquee-component_col mdmws_item clonedBefore">
                      <div className="client-logo-wrapper">
                        <div className='testimonial-logo'>
                          <img src={clientLogo12} alt="lemon"/>
                        </div>
                        <div className="country-flag-text">
                          <div className="flag-image">
                            <img src={flagImage5}  />
                          </div>
                          <span className="country-name">Singapore</span>
                        </div>
                      </div>
                    </div>
                    <div className="marquee-component_col mdmws_item clonedBefore">
                      <div className="client-logo-wrapper">
                        <div className='testimonial-logo'>
                          <img src={clientLogo5} alt="boyu"/>
                        </div>
                        <div className="country-flag-text">
                          <div className="flag-image">
                            <img src={flagImage5}  />
                          </div>
                          <span className="country-name">Canada</span>
                        </div>
                      </div>
                    </div>
                    <div className="marquee-component_col mdmws_item clonedBefore">
                      <div className="client-logo-wrapper">
                        <div className='testimonial-logo'>
                          <img src={clientLogo7} alt="rowe beef"/>
                        </div>
                        <div className="country-flag-text">
                          <div className="flag-image">
                            <img src={flagImage5}  />
                          </div>
                          <span className="country-name">Canada</span>
                        </div>
                      </div>
                    </div>
                    <div className="marquee-component_col mdmws_item clonedBefore">
                      <div className="client-logo-wrapper">
                        <div className='testimonial-logo'>
                          <img src={clientLogo8} alt="accurak"/>
                        </div>
                        <div className="country-flag-text">
                          <div className="flag-image">
                            <img src={flagImage5}  />
                          </div>
                          <span className="country-name">Canada</span>
                        </div>
                      </div>
                    </div>
                    <div className="marquee-component_col mdmws_item clonedBefore">
                      <div className="client-logo-wrapper">
                        <div className='testimonial-logo'>
                          <img src={clientLogo10} alt="muslim delight"/>
                        </div>
                        <div className="country-flag-text">
                          <div className="flag-image">
                            <img src={flagImage6}  />
                          </div>
                          <span className="country-name">Singapore</span>
                        </div>
                      </div>
                    </div>
                    <div className="marquee-component_col mdmws_item clonedBefore">
                      <div className="client-logo-wrapper">
                        <div className='testimonial-logo'>
                          <img src={clientLogo11} alt="womenwellness"/>
                        </div>
                        <div className="country-flag-text">
                          <div className="flag-image">
                            <img src={flagImage6}  />
                          </div>
                          <span className="country-name">Singapore</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </marquee-component>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='hwItWrkSection'>
        <div className='container'>
          <header className='sectionHeader txtCenter fontPoppins'>
            <h1 className='h1'>
              <span className='txtOutline txtUppercase dBlock'>How it works </span>
              Unveiling the Process
            </h1>
            <p>Experience a hassle-free approach to software development.</p>
          </header>
          <ul className='hiwList txtCenter'>
            <li>
              <article className='hiwWidget'>
                <span className='hiwCounter'></span>
                <strong className='hiwTitle txtUppercase dBlock'>Subscribe to start</strong>
                <p>Begin by subscribing to our plan. <a href="/pricing">Subscribe</a></p>
              </article>
            </li>
            <li>
              <article className='hiwWidget'>
                <span className='hiwCounter'></span>
                <strong className='hiwTitle txtUppercase dBlock'>Access Jira & Slack</strong>
                <p>Gain access to Linear Board and Slack.</p>
              </article>
            </li>
            <li>
              <article className='hiwWidget'>
                <span className='hiwCounter'></span>
                <strong className='hiwTitle txtUppercase dBlock'>Add Tasks to Jira</strong>
                <p>Achieve sequential task completion by adding tasks to the Linear Board.</p>
              </article>
            </li>
            <li>
              <article className='hiwWidget'>
                <span className='hiwCounter'></span>
                <strong className='hiwTitle txtUppercase dBlock'>Slack Communication</strong>
                <p>Enjoy seamless communication through Slack - questions are always welcome, and meetings are minimal.</p>
              </article>
            </li>
          </ul>
        </div>
      </section>
      <section className='contactSection'>
        <div className='container'>
          <div className='omlRow'>
            <div className='omlCol csCol40'>
              <header className='sectionHeader'>
                <h1 className='h1 txtUppercase'>
                  <span className='txtOutline dBlock'>Submit
                    <span className='txtTag'>Submit Awesome</span>
                  </span>
                  Your Idea
                </h1>
              </header>
              <article className='contactWidget fontPoppins'>
                <span className='circle'></span>
                <h2 className='h2'>Free 30-Minute Consultation</h2>
                <p>Have a new project in mind? Schedule a 30-minute discovery call or fill out the quick form. And don't forget to catch us at Gitex Dubai - booth P27 H26, where we can explore the possibilities in person.</p>
                <button onClick={openModal} className='schDisLink txtUppercase'>Schedule discovery call</button>
              </article>
            </div>
            <div className='omlCol csCol60'>
              <ContactForm />
              <span className='emlTxt fontPoppins'>Or email us at</span>
              <a href="mailto:Info@Omelatte.com" className='emailLink'>Info@Omelatte.com</a>
            </div>
          </div>
        </div>
      </section>
      <ScheduleCall
        isOpen={isModalOpen}
        onRequestClose={closeModal}
      />
    </>
  );
}

export default Home;